$(window).load(function() {
  $(".section-title-group").viewportChecker({
      classToAdd: "visible animated fadeInDown",
      classToRemove: 'invisible  animated fadeInDown',
      offset: 100
  }), $(".news-link").viewportChecker({
      classToAdd: "visible animated fadeInUp",
      offset: 100
  }), $(".item-title").viewportChecker({
      classToAdd: "visible animated fadeIn",
      offset: 100
  }), $(".contact-sitemap .contact-area").viewportChecker({
      classToAdd: "visible animated fadeInLeft",
      offset: 100
  }), $(".contact-sitemap .fb-area").viewportChecker({
      classToAdd: "visible animated fadeInRight",
      offset: 100
  }), $(".products-category-menu ul").viewportChecker({
      classToAdd: "visible animated fadeIn",
      offset: 100
  }), $(".category-item").viewportChecker({
      classToAdd: "visible animated fadeInUp",
      classToRemove: "invisible",
      offset: 100
  }), $(".tab-pane-row").viewportChecker({
      classToAdd: "visible animated fadeInUp",
      offset: 100
  }), $(".relproducts-carousel").viewportChecker({
      classToAdd: "visible animated fadeInUp",
      classToRemove: "invisible",
      offset: 100
  }), $(".news-item:nth-child(1n+2)").viewportChecker({
      classToAdd: "visible animated fadeInUp",
      classToRemove: "invisible",
      offset: 100
  }), $(".aboutus").viewportChecker({
      classToAdd: "visible animated fadeInUp",
      offset: 100
  }), $(".aboutus-company-img").viewportChecker({
      classToAdd: "visible animated fadeInUp",
      offset: 100
  }), $(".aboutus-company-intro").viewportChecker({
      classToAdd: "visible animated fadeInUp",
      offset: 100
  }), $("#contact-form").viewportChecker({
      classToAdd: "visible animated fadeInUp",
      offset: 100
  }), $(".contact-info-datalist").viewportChecker({
      classToAdd: "visible animated fadeInLeft",
      offset: 100
  }), $(".contact-info-map").viewportChecker({
      classToAdd: "visible animated fadeInRight",
      offset: 100
  }), $(".awards-item:nth-child(1n+2)").viewportChecker({
      classToAdd: "visible animated fadeInUp",
      offset: 100
  }), $(".branches-item:nth-child(1n+2)").viewportChecker({
      classToAdd: "visible animated fadeInUp",
      offset: 100
  }), $(".news-item.details .news-item-img").viewportChecker({
      classToAdd: "visible animated fadeInUp",
      offset: 100
  }), $(".news-item.details .news-item-text").viewportChecker({
      classToAdd: "visible animated fadeInUp",
      offset: 100
  })
});